import * as React from 'react';
import NextError, { ErrorProps as NextErrorProps } from 'next/error';
// import * as Sentry from '@sentry/node';
import { NextPageContext } from 'next';

// #region Interface Imports
// #endregion Global Imports

// #region Local Imports
// const { useTranslation } = require("../utils/i18n/i18n");
// #endregion Local Imports
// #endregion Interface Imports

export type ErrorPageProps = {
    err: Error;
    statusCode: number;
    isReadyToRender: boolean;
    children?: React.ReactElement;
};

export type ErrorProps = {
    isReadyToRender: boolean;
} & NextErrorProps;

const ErrorPage = (props: ErrorPageProps) => {
    // const { t } = useTranslation();

    const { statusCode, isReadyToRender, err, children = null } = props;
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'prod') {
        console.debug(
            'ErrorPage - Unexpected error caught, it was captured and sent to Sentry. Error details:'
        ); // eslint-disable-line no-console
        console.error(statusCode, err); // eslint-disable-line no-console
    }
    // TODO rename to "forceLogTopLevelError" = true and provide false in "DefaultErrorLayout"
    if (!isReadyToRender && err) {
        // XXX getInitialProps is not called for top-level errors - See https://github.com/vercel/next.js/issues/8592
        // As a workaround, we pass err via _app and src/components/appBootstrap/MultiversalAppBootstrap.tsx so it can be captured
        // Sentry.captureException(err);
    }

    return <div>{'Page not found or something went wrong'}</div>;
};

ErrorPage.getInitialProps = async (props: NextPageContext): Promise<ErrorProps> => {
    const { res, err, asPath } = props;
    const errorInitialProps: ErrorProps = (await NextError.getInitialProps({
        res,
        err
    } as NextPageContext)) as ErrorProps;
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'prod') {
        console.debug(
            'ErrorPage.getInitialProps - Unexpected error caught, it was captured and sent to Sentry. Error details:',
            err
        );
    }

    // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
    // getInitialProps has run
    errorInitialProps.isReadyToRender = true;

    // let statusCode;
    //
    // if (res) {
    //   Sentry.captureException(err);
    //   ({ statusCode } = res);
    // } else if (err) {
    //   ({ statusCode } = err);
    // }

    if (res) {
        // Running on the server, the response object is available.
        //
        // Next.js will pass an err on the server if a page's `getInitialProps`
        // threw or returned a Promise that rejected

        // XXX Opinionated: Record an exception in Sentry for 404, if you don't want this then uncomment the below code
        // if (res.statusCode === 404) {
        //   return { statusCode: 404, isReadyToRender: true };
        // }

        if (err) {
            // Sentry.captureException(err);

            return errorInitialProps;
        }
    } else {
        // Running on the client (browser).
        //
        // Next.js will provide an err if:
        //
        //  - a page's `getInitialProps` threw or returned a Promise that rejected
        //  - an exception was thrown somewhere in the React lifecycle (render,
        //    componentDidMount, etc) that was caught by Next.js's React Error
        //    Boundary. Read more about what types of exceptions are caught by Error
        //    Boundaries: https://reactjs.org/docs/error-boundaries.html
        if (err) {
            // Sentry.captureException(err);

            return errorInitialProps;
        }
    }

    // If this point is reached, getInitialProps was called without any
    // information about what the error might be. This is unexpected and may
    // indicate a bug introduced in Next.js, so record it in Sentry
    // Sentry.captureException(
    //   new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
    // );

    return errorInitialProps;

    // return {
    //   namespacesRequired: ["common"],
    //   statusCode,
    // };
};

export default ErrorPage;
